const config = {
  AELLA_MERCHANT_URL: process.env.REACT_APP_WALLET_URL,
  AELLA_BASE_URL: process.env.REACT_APP_BASE_URL,
  AELLA_INDEX_DASH: process.env.REACT_APP_INDEX_DASH_URL,
  AELLA_NLP_CHART_URL: process.env.REACT_APP_NLP_CHART_URL,
  AELLA_CARE_CHART_URL: process.env.REACT_APP_AELLA_CARE_CHART_URL,
  AELLA_CARE_BILL_PAYMENTS_CHART_URL:
    process.env.REACT_APP_BILL_PAYMENTS_CHART_URL,
  AELLA_CARE_TRANSFERS_CHART_URL: process.env.REACT_APP_TRANSFERS_CHART_URL,
};
export default config;
