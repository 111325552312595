import styled from 'styled-components';

export const ProfileStyle = styled.div`
  display: grid;
  grid-template-columns: 80px 240px 240px;
  column-gap: 24px;

  .image_wrapper {
    width: 80px;
    height: 80px;
    background: #000;
    border-radius: 100%;
  }

  .reg14 {
    margin-bottom: 2px;
  }

  .mid18 {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const ChangePasswordStyle = styled.div`
  h3 {
    margin-bottom: 8px;
  }

  .sub_text {
    margin-bottom: 24px;
  }

  .container {
    width: 40%;
  }

  .btn {
    height: 50px;

    & button {
      width: 100%;
      height: 100%;
    }
  }

  .input_file {
    width: 100%;
    height: 100%;
    left: 0;
  }
`;

export const DeveloperKeysStyle = styled.div`
  form {
    width: 45%;
  }

  .mid18 {
    margin-bottom: 32px;
  }

  .btn {
    height: 50px;
  }

  .key_type {
    text-transform: capitalize;
  }

  .empty_state_container {
    height: 400px;
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reg24 {
    margin-bottom: 12px;
  }

  .modal_btn {
    padding: 0 32px;
    margin-top: 16px;
  }

  .btn {
    height: 50px;

    & button {
      width: 100%;
      height: 100%;
      color: ${props => props.theme.colors.aellawhite};
      background: ${props => props.theme.colors.aellablue};
    }
  }

  .reg14 {
    margin-bottom: 4px;
    display: inline-flex;
  }

  .mid16 {
    margin-bottom: 16px;
  }

  .keys_wrapper {
    height: 400px;
    overflow: scroll;
  }

  .keys_inner_wrapper {
    display: flex;
    align-items: flex-start;
  }

  .keys_container {
    margin-bottom: 24px;
    background: #eed3c5;
    padding: 12px;
    border-radius: 10px;
  }

  .pointer {
    cursor: pointer;
    margin-left: 16px;
  }

  .pointer_key {
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    padding: 3px 6px;
    border-radius: 6px;
  }
`;
