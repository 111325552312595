import { useContext, useEffect } from 'react';
import { ModalContext } from 'context/modals';
import { AuthContext } from 'context/auth';
import { DashStyle } from './style';

export const DashHeader = () => {
  const { userData } = useContext(AuthContext);
  const { createMerchantModal, setCreateMerchantModal } =
    useContext(ModalContext);
  const { userProfile, userIsMerchant, setUserIsMerchant } =
    useContext(AuthContext);

  useEffect(() => {
    if (userProfile.merchant_id === null) {
      setCreateMerchantModal(!createMerchantModal);
      setUserIsMerchant(!userIsMerchant);
    }
    // eslint-disable-next-line
  }, [userProfile]);

  return (
    <DashStyle>
      <div className=''>
        <div className='greeting reg24'>
          Hi, &nbsp;
          <span className=''>{userData?.first_name}👋🏾</span>
        </div>
      </div>
      {!userIsMerchant && (
        <div>
          <button
            onClick={() => setCreateMerchantModal(!createMerchantModal)}
            className='modal_btn'>
            Create Merchant Account
          </button>
        </div>
      )}
    </DashStyle>
  );
};
