import styled from 'styled-components';

export const NestedNavStyle = styled.div`
  .nav_wrapper {
    width: 100%;
    max-width: 800px;
    min-height: 617px;
    height: fit-content;
    background: ${props => props.theme.colors.aellawhite};
    padding: 20px 24px;
    border-radius: 8px;
  }

  .tab_list {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .tab_list::-webkit-scrollbar {
    display: none;
  }

  .divider {
    margin: 20px 0;
    border: 0.5px solid #edeff5;
  }

  .tab_item {
    width: fit-content;
    color: #4f4f4f;
    background: none;
    transition: all 0.5s;
    font-weight: 300;
    cursor: pointer;
  }

  .tab_item_wrapper {
    display: flex;
    margin-right: 30px;
  }

  .active_tab {
    font-weight: 500;
    color: ${props => props.theme.colors.aellablack};
  }

  .selected {
    color: ${props => props.theme.colors.aellablue};
    font-weight: 500;
  }

  .badge {
    margin-left: 8px;
  }
`;
