import { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [userData, setUserData] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [userIsMerchant, setUserIsMerchant] = useState(true);
  const [profileIsLoaded, setProfileIsLoaded] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('_AELLA_TOKEN')) {
      setIsAuthed(true);
    } else {
      setIsAuthed(false);
    }
    if (sessionStorage.getItem('aellaDashUserToken')) {
      const stringifiedUserData = sessionStorage.getItem('aellaDashUserToken');
      const userData = JSON.parse(stringifiedUserData);
      setUserData(userData);
    } else {
      setUserData({});
    }
  }, [isAuthed]);

  return (
    <AuthContext.Provider
      value={{
        userData,
        isAuthed,
        setIsAuthed,
        userProfile,
        setUserProfile,
        userIsMerchant,
        setUserIsMerchant,
        profileIsLoaded,
        setProfileIsLoaded,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
