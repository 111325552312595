import config from './index';
import { isDefined } from 'utils';
import axios from 'axios';
import toast from 'react-hot-toast';

let headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': '*',
  Authorization: '',
};

export const handleErrors = (res: any, history?: any) => {
  const response = res.response;
  if (response === undefined) {
  } else {
    if (response.status.toString().startsWith('2')) {
      let status = response.status;
      if (status === 201) {
        toast.success(response.data.message);
      }
      return response;
    }

    if (response.status.toString().startsWith('5')) {
    }

    if (response.status.toString().startsWith('4')) {
      let status = response.status;
      if (status === 401) {
        sessionStorage.removeItem('_AELLA_TOKEN');
        sessionStorage.removeItem('aellaDashUserToken');
        history.push('/login');
      }
      toast.error(response.data.message);
    }
  }
};

export const fetcher = async (url: string, data: any) => {
  if (isDefined(sessionStorage.getItem('_AELLA_TOKEN'))) {
    let token = sessionStorage.getItem('_AELLA_TOKEN');
    headers['Authorization'] = `Bearer ${token}`;
  }

  let response = await axios.post(`${config.AELLA_BASE_URL}${url}`, data, {
    headers,
  });
  return response.data;
};

export const patchClient = async (url: string, data: any = null) => {
  if (isDefined(sessionStorage.getItem('_AELLA_TOKEN'))) {
    let token = sessionStorage.getItem('_AELLA_TOKEN');
    headers['Authorization'] = `Bearer ${token}`;
  }

  let response = await axios.patch(`${config.AELLA_BASE_URL}${url}`, data, {
    headers,
  });
  return response.data;
};

export const queryClient = async ({ queryKey }: any) => {
  const [url] = queryKey;
  if (isDefined(sessionStorage.getItem('_AELLA_TOKEN'))) {
    let token = sessionStorage.getItem('_AELLA_TOKEN');
    headers['Authorization'] = `Bearer ${token}`;
  }
  let response = await axios.get(`${config.AELLA_BASE_URL}${url}`, {
    headers,
  });
  return response.data;
};

//Save session after user logs in
export const saveSession = (response: any) => {
  const aellaDashUserToken = response.data.token;
  const aellaDashUser = JSON.stringify(response.data);
  sessionStorage.setItem('aellaDashUserToken', aellaDashUser);
  sessionStorage.setItem('_AELLA_TOKEN', aellaDashUserToken);
};
