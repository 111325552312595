import React from 'react';
import { useContext } from 'react';
import { SelectedItems } from 'context/selected_items';
import { Switch, Route } from 'react-router-dom';
import { DeveloperKeys, WebHooks, Profile } from 'components/Settings';
import { NestedNav } from 'components/NestedNav';

export const Login = React.lazy(() => import('./Login'));
export const Register = React.lazy(() => import('./Register'));
export const Home = React.lazy(() => import('./Home'));
export const Wallet = React.lazy(() => import('./Wallet'));
export const Transactions = React.lazy(() => import('./Transactions'));
export const Settings = React.lazy(() => import('./Settings'));
export const Customers = React.lazy(() => import('./Customers'));
export const NotFound = React.lazy(() => import('./NotFound'));

export const Main = () => {
  const { merchantData } = useContext(SelectedItems);
  return (
    <main>
      <Switch>
        <Route exact path='/dashboard'>
          <Home />
        </Route>
        <Route exact path='/wallet'>
          <Wallet />
        </Route>
        <Route exact path='/transactions'>
          <Transactions />
        </Route>
        <Route exact path='/customers'>
          <Customers />
        </Route>
        <Route exact path='/settings'>
          <Settings />
        </Route>

        <Route path='/settings/profile'>
          <div className='main_dash_wrapper nested_body'>
            <NestedNav>
              <Profile
                address={merchantData?.address}
                name={merchantData?.name}
                email={merchantData?.email}
                phone_number={merchantData?.phone_number}
              />
            </NestedNav>
          </div>
        </Route>

        <Route exact path='/settings/developer-keys'>
          <div className='main_dash_wrapper nested_body'>
            <NestedNav>
              <DeveloperKeys />
            </NestedNav>
          </div>
        </Route>

        <Route exact path='/settings/webhooks'>
          <div className='main_dash_wrapper nested_body'>
            <NestedNav>
              <WebHooks />
            </NestedNav>
          </div>
        </Route>

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </main>
  );
};
