import { AuthLayoutStyle } from './Auth.style';
import { ReactComponent as AellaLogo } from '../../assets/icons/brand/aella_logo.svg';

type authProps = {
  children: JSX.Element;
};

export const AuthLayout = ({ children }: authProps) => {
  return (
    <AuthLayoutStyle>
      <div className='auth_title'>
        <span className='mid24'> Welcome To</span>
        <AellaLogo />
      </div>

      <div className='auth_children_container'>{children}</div>
    </AuthLayoutStyle>
  );
};
