import styled from 'styled-components';

export const SidebarStyle = styled.aside`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px 10px 32px 40px;
  overflow: auto;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  width: 256px;
  margin-top: 60px;

  .brand {
    margin-bottom: 30px;
  }

  .logout_btn {
    background: rgba(0, 0, 0, 0);
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    border: 1px solid ${props => props.theme.colors.aellablue};
    padding: 0 !important;
    margin-bottom: 60px;
  }

  .sidebar_link {
    width: 100%;
    height: 52px;
    color: ${props => props.theme.colors.aellagrey};
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding-left: 20px;
  }

  .badge_wrapper {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .active_link {
    background-color: #f6f8fd;
    color: ${props => props.theme.colors.aellablack};
    border: 1px solid ${props => props.theme.colors.aellablue};
    font-weight: 500;
  }

  .link_title {
    margin-left: 16px;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const MobileSidebarStyle = styled(SidebarStyle)<{
  mobileToggler?: boolean;
}>`
  width: 100%;
  position: fixed;
  z-index: 3;
  height: 100vh;
  top: 0;
  left: 0;
  display: block;
  background: #fff;
  padding: 24px 10px 32px 16px;
  transition: all 0.3s;
  transform: ${props =>
    props.mobileToggler ? 'translateX(0)' : 'translateX(-100vw)'};
`;
