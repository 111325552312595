import styled from 'styled-components';

export const AuthLayoutStyle = styled.div`
  min-height: 100vh;
  width: 100vw;
  overflow: auto;
  background-color: ${props => props.theme.colors.aellabluesecondary};
  padding-top: 80px;

  .auth_title {
    width: 200px;
    text-align: center;
    color: ${props => props.theme.colors.aellablack};
    margin: 0 auto;
  }

  .auth_children_container {
    margin-top: 40.74px;
    width: 540px;
    height: fit-content;
    background: ${props => props.theme.colors.aellawhite};
    box-shadow: 0px 4px 40px rgba(12, 22, 47, 0.05);
    border-radius: 8px;
    margin: 16px auto;
  }

  @media only screen and (max-width: 600px) {
    padding: 80px 24px;

    .auth_children_container {
      width: 100%;
    }
  }
`;
