import styled from 'styled-components';

export const HeaderStyle = styled.nav`
  position: fixed;
  z-index: 1;
  top: 0%;
  height: 60px;
  width: 100%;
  background: ${props => props.theme.colors.aellablue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .active_link {
    background: #34c759;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    transition: 0.4s;
    height: 20.9px;
    width: 20.9px;
    background-color: #ffffff;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    border-radius: 100%;
  }

  .active_slide {
    left: 17px;
  }

  .toggle_wrapper {
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .toggle_wrapper p {
    margin-right: 8px;
    color: #ffffff;
  }

  .hamburger_outer {
    display: flex;
    align-items: flex-end;
  }

  .hamburger_wrapper {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .hamburger_wrapper {
      display: none;
    }

  @media only screen and (max-width: 600px) {
    padding: 0 24px;

    .hamburger_wrapper {
      display: flex;
    }
  }
`;
