import styled from 'styled-components';

export const LoginStyle = styled.div`
  padding: 40px;
  overflow: scroll;
  margin: 0 auto;
  min-height: 400px;

  .auth_text {
    color: ${props => props.theme.colors.aellagrey};
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .auth_forgot_pass {
    margin-bottom: 24px;

    a {
      color: ${props => props.theme.colors.aellablue};
    }
  }

  a{
    color: ${props => props.theme.colors.aellablue};
    display: inline-flex;
    margin-bottom: 16px;
  }

  .login_btn_wrapper {
    height: 48px;
    width: 100%;
  }

  .select {
    width: 100%;
    height: 56px;
    padding: 0 0 0 16px;
    border: 1px solid rgba(185, 185, 185, 0.7);
    font-size: 1.125rem;
    border-radius: 4px;
    font-weight: 300;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 600px) {
    padding: 24px 16px 32px 16px;
  }
`;
