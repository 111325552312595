import React from 'react';
import { DefaultButtonStyle, IconButtonStyle } from './Button.style';

type defaultButtonProp = {
  children: React.ReactNode;
  type?: string;
  disabled?: boolean;
  isSecondary?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
export const ButtonDefault = ({
  children,
  disabled,
  isSecondary,
  onClick,
  ...prop
}: defaultButtonProp) => {
  return (
    <DefaultButtonStyle
      onClick={onClick}
      isSecondary={isSecondary}
      disabled={disabled}>
      {children}
    </DefaultButtonStyle>
  );
};

export const IconButton = ({ children, ...prop }: defaultButtonProp) => {
  return <IconButtonStyle>{children}</IconButtonStyle>;
};
