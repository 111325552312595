import {
  IconInputStyle,
  TextAreaStyle,
  SelectStyle,
  SearchInputStyle,
  SelectFileStyle,
} from './style';
import { ReactComponent as SearchIcon } from 'assets/icons/main/u_search.svg';
import { iconInputProps, search, text_area } from './type';

export const Input = ({
  label,
  placeholder,
  name,
  type,
  register,
  isSearch,
  errors,
  maxLength,
  minLength,
  errorMessage,
  isModal,
  regex,
  ...rest
}: iconInputProps) => {
  return (
    <IconInputStyle is_modal={isModal} is_search={isSearch}>
      {label === '' ? null : (
        <label className='input_label' htmlFor={name}>
          {label}
        </label>
      )}
      <div className='icon_input_container'>
        <input
          className='input'
          name={name}
          placeholder={placeholder}
          id={name}
          type={type}
          {...rest}
        />
        {errors && errors.name && ''}
      </div>
    </IconInputStyle>
  );
};

export const SearchInput = ({ name, placeholder, onChange, value }: search) => {
  return (
    <SearchInputStyle>
      <div className='icon_input_container'>
        <div className='input_icon'>
          <SearchIcon />
        </div>
        <input
          onChange={onChange}
          className='input'
          type='text'
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
        />
      </div>
    </SearchInputStyle>
  );
};

export const SelectFile = ({
  label,
  name,
  placeholder,
  register,
  ...rest
}: any) => {
  return (
    <SelectFileStyle>
      <>
        <label className='input_label' htmlFor={name}>
          {label}
        </label>
        <div className='icon_input_container'>
          <input
            className='input input_file'
            {...register(name, { required: true })}
            name={name}
            placeholder={placeholder}
            id={name}
            type='file'
            {...rest}
          />
        </div>
      </>
    </SelectFileStyle>
  );
};

export const TextArea = ({
  label,
  placeholder,
  name,
  isModal,
  ...rest
}: text_area) => {
  return (
    <TextAreaStyle is_modal={isModal}>
      <label className='reg12' htmlFor={name}>
        {label}
      </label>
      <textarea
        placeholder={placeholder}
        name={name}
        id={name}
        {...rest}></textarea>
    </TextAreaStyle>
  );
};

export const SelectInput = ({ label, name, register, options }: text_area) => {
  return (
    <SelectStyle>
      <label className='reg12' htmlFor={name}>
        {label}
      </label>
      <select {...register(name)}>
        {options?.map((value: any, index: number) => (
          <option key={index} value={value.id}>
            {value.type}
          </option>
        ))}
      </select>
    </SelectStyle>
  );
};
