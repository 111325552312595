import { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalContextsProvider = ({ children }) => {
  const [customerWalletDrawer, setCustomerWalletDrawer] = useState(false);
  const [addTeamMemberModal, setAddTeamMemberModal] = useState(false);
  const [createMerchantModal, setCreateMerchantModal] = useState(false);
  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        customerWalletDrawer,
        setCustomerWalletDrawer,
        addTeamMemberModal,
        setAddTeamMemberModal,
        createMerchantModal,
        setCreateMerchantModal,
        generateKeyModal,
        setGenerateKeyModal,
        mobileSidebar,
        setMobileSidebar,
      }}>
      {children}
    </ModalContext.Provider>
  );
};
