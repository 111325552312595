export const Home = ({ color }: any) => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={`${color}`}
        d='M8.75 0h2.5v1.25h-2.5zM7.5 1.25h1.25V2.5H7.5zM6.25 2.5H7.5v1.25H6.25zM5 3.75h1.25V5H5zM3.75 5H5v1.25H3.75zM2.5 6.25h1.25V7.5H2.5zM1.25 7.5H2.5v1.25H1.25zM7.5 5h1.25v1.25H7.5zM6.25 6.25H7.5V7.5H6.25zM5 7.5h1.25v1.25H5zM3.75 8.75H5V10H3.75zM2.5 10h1.25v8.75H2.5zM11.25 3.75h-2.5V5h2.5zM16.25 18.75H3.75V20h12.5zM12.5 5h-1.25v1.25h1.25zM13.75 6.25H12.5V7.5h1.25zM15 7.5h-1.25v1.25H15zM16.25 8.75H15V10h1.25zM17.5 10h-1.25v8.75h1.25zM0 8.75h1.25V10H0zM12.5 1.25h-1.25V2.5h1.25zM13.75 2.5H12.5v1.25h1.25zM15 3.75h-1.25V5H15zM16.25 1.25H15v5h1.25zM17.5 6.25h-1.25V7.5h1.25zM18.75 7.5H17.5v1.25h1.25zM20 8.75h-1.25V10H20z'
      />
    </svg>
  );
};

export const Wallet = ({ color }: any) => {
  return (
    <svg width='22' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={`${color}`}
        d='M1.125 4.5H18v1.125H1.125zM1.125 7.875H18V9H1.125zM2.25 12.375h1.125V13.5H2.25zM4.5 12.375h1.125V13.5H4.5zM6.75 12.375h1.125V13.5H6.75zM1.125 16.875H18V18H1.125zM0 5.625h1.125v11.25H0zM18 5.625h1.125v11.25H18zM3.375 0H20.25v1.125H3.375zM2.25 1.125h1.125V4.5H2.25zM19.125 12.375h1.125V13.5h-1.125zM20.25 1.125h1.125v11.25H20.25z'
      />
    </svg>
  );
};

export const Transactions = ({ color }: any) => {
  return (
    <svg width='20' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={`${color}`} d='M10 16H8v2h2z' />
      <path
        fill={`${color}`}
        d='M10 16H8v2h2zM12 18h-2v2h2zM14 20h-2v2h2zM8 14H6v2h2zM6 12H4v2h2zM4 10H2v2h2zM2 8H0v8h2zM8 8H2v2h6zM10 6h2V4h-2z'
      />
      <path
        fill={`${color}`}
        d='M10 6h2V4h-2zM8 4h2V2H8zM6 2h2V0H6zM12 8h2V6h-2zM14 10h2V8h-2zM16 12h2v-2h-2zM18 14h2V6h-2zM12 14h6v-2h-6z'
      />
    </svg>
  );
};

export const Settings = ({ color }: any) => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'
        stroke={`${color}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.167 12.5a1.375 1.375 0 00.275 1.517l.05.05a1.667 1.667 0 11-2.359 2.358l-.05-.05a1.376 1.376 0 00-1.516-.275 1.375 1.375 0 00-.834 1.258v.142a1.666 1.666 0 11-3.333 0v-.075a1.375 1.375 0 00-.9-1.258 1.375 1.375 0 00-1.517.275l-.05.05a1.667 1.667 0 11-2.358-2.359l.05-.05a1.375 1.375 0 00.275-1.516 1.374 1.374 0 00-1.258-.834H2.5a1.667 1.667 0 110-3.333h.075a1.375 1.375 0 001.258-.9 1.375 1.375 0 00-.275-1.517l-.05-.05a1.667 1.667 0 112.359-2.358l.05.05a1.375 1.375 0 001.516.275H7.5a1.375 1.375 0 00.833-1.258V2.5a1.667 1.667 0 013.334 0v.075a1.375 1.375 0 00.833 1.258 1.375 1.375 0 001.517-.275l.05-.05a1.667 1.667 0 112.358 2.359l-.05.05a1.376 1.376 0 00-.275 1.516V7.5a1.375 1.375 0 001.258.833h.142a1.666 1.666 0 110 3.334h-.075a1.375 1.375 0 00-1.258.833v0z'
        stroke={`${color}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Customers = ({ color }: any) => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={`${color}`}
        d='M5.41663 5.83337h-2.5v-.833333h2.5zM2.91663 5h-.833333v-.833333h.833333zM2.91663 2.5h-.833333v-.833333h.833333z'
      />
      <path
        fill={`${color}`}
        d='M2.08337 4.16663h-.833333V2.49996h.833333zM5.41663 5l.833333 7e-8 7e-8-.833333-.833333-7e-8zM5.41663 2.5l.833333 7e-8 7e-8-.833333-.833333-7e-8zM2.91663 1.66663l2.5 2.2e-7 7e-8-.833334-2.5-2.2e-7zM6.25 4.16663l.833333 7e-8 1.5e-7-1.66667-.833333-7e-8zM2.91663 6.66663h-.833333v-.833333h.833333zM5.41663 6.66663l.833333-7e-8-7e-8-.833333-.833333 7e-8zM2.08337 7.5h-.833333v-.833333h.833333zM6.25 7.5l.833333-7e-8-7e-8-.833333-.833333 7e-8zM1.25 10H.416666V7.5H1.25zM7.08337 10.8334H1.25004v-.833333h5.83333zM7.08337 10l.833334-7e-8-2.2e-7-2.5-.833334 7e-8zM16.6666 5.83337h-2.5v-.833333h2.5zM14.1666 5h-.833333v-.833333h.833333zM14.1666 2.5h-.833333v-.833333h.833333z'
      />
      <path
        fill={`${color}`}
        d='M13.3334 4.16663h-.833333V2.49996h.833333zM16.6666 5l.833333 7e-8 7e-8-.833333-.833333-7e-8zM16.6666 2.5l.833333 7e-8 7e-8-.833333-.833333-7e-8zM14.1666 1.66663l2.5 2.2e-7 7e-8-.833334-2.5-2.2e-7zM17.5 4.16663l.833333 7e-8 1.5e-7-1.66667-.833333-7e-8zM14.1666 6.66663h-.833333v-.833333h.833333zM16.6666 6.66663l.833333-7e-8-7e-8-.833333-.833333 7e-8zM13.3334 7.5h-.833333v-.833333h.833333zM17.5 7.5l.833333-7e-8-7e-8-.833333-.833333 7e-8zM12.5 9.16663h-.833333V7.49996H12.5zM18.3334 10h-5.83333v-.833333h5.83333zM18.3334 9.16663l.833333-7e-8-1.5e-7-1.66667-.833333 7e-8zM11.6666 15h-2.5v-.833333h2.5zM9.16663 14.1666h-.833333v-.833333h.833333zM9.16663 11.6666h-.833333v-.833333h.833333z'
      />
      <path
        fill={`${color}`}
        d='M8.33337 13.3334h-.833333v-1.66667h.833333zM11.6666 14.1666l.833333 7e-8 7e-8-.833333-.833333-7e-8zM11.6666 11.6666l.833333 7e-8 7e-8-.833333-.833333-7e-8z'
      />
      <path
        fill={`${color}`}
        d='M9.16663 10.8334l2.5 2.2e-7 7e-8-.833334-2.5-2.2e-7zM12.5 13.3334l.833333 7e-8 1.5e-7-1.66667-.833333-7e-8zM9.16663 15.8334h-.833333v-.833333h.833333zM11.6666 15.8334l.833333-7e-8-7e-8-.833333-.833333 7e-8z'
      />
      <path
        fill={`${color}`}
        d='M8.33337 16.6666h-.833333v-.833333h.833333zM12.5 16.6666l.833333-7e-8-7e-8-.833333-.833333 7e-8zM7.5 18.3334h-.833333v-1.66667H7.5zM13.3334 19.1666H7.50007v-.833333h5.83333z'
      />
      <path
        fill={`${color}`}
        d='M13.3334 18.3334l.833333-7e-8-1.5e-7-1.66667-.833333 7e-8zM14.1666 13.75h.833333v.833333H14.1666zM15 12.9166h.833333v.833333H15zM3.33337 12.9166h.833333v-.833333H3.33337zM15.8334 12.0834h.833333v.833333H15.8334zM4.16663 13.75h.833333v-.833333H4.16663zM5 14.5834h.833333v-.833333H5zM8.75 3.75h2.5v.833333h-2.5z'
      />
    </svg>
  );
};

export const LogoBlueBG = () => {
  return (
    <svg width='86' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.081 9.353v20.092h5.098V9.353h-5.098zM79.956 9.353v20.092h5.098V9.353h-5.098zM44.476 21.498h-15.2c.675 2.53 2.568 3.748 5.623 3.748a5.322 5.322 0 004.517-2.005l4.198 2.418a10.008 10.008 0 01-8.734 4.33 10.852 10.852 0 01-8.022-3.018 10.344 10.344 0 01-3.036-7.628 10.477 10.477 0 012.999-7.498 10.122 10.122 0 017.665-3.055 9.371 9.371 0 017.291 3.055 10.627 10.627 0 012.905 7.591c-.02.691-.09 1.38-.206 2.062zm-15.275-4.03h10.27a4.873 4.873 0 00-4.985-4.086 5.436 5.436 0 00-3.467 1.069 5.098 5.098 0 00-1.818 3.017zM52.498 29.445H47.27V0h5.23v29.445zM61.195 29.445h-5.21V0h5.21v29.445z'
        fill='#FFFCF4'
      />
      <path
        d='M21.104 19.399A10.553 10.553 0 110 19.436a10.553 10.553 0 0121.105-.037zm-15.95 0a5.398 5.398 0 1010.796 0 5.398 5.398 0 00-10.796 0zM84.98 19.399a10.553 10.553 0 11-21.106.037 10.553 10.553 0 0121.105-.037zm-15.95 0A5.398 5.398 0 1074.426 14a5.417 5.417 0 00-5.398 5.398z'
        fill='#D8A28F'
      />
    </svg>
  );
};

export const Hamburger = (props: { onClick: () => void }) => {
  return (
    <svg
      onClick={props.onClick}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width='24'
      height='24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z'
        fill='rgba(255,255,255,1)'
      />
    </svg>
  );
};
