import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from 'context/auth';

export const ProtectedRoute = ({ children, ...rest }: any) => {
  const { isAuthed } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthed === true) {
          return children;
        } else {
          <Redirect
            exact
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />;
        }
      }}
    />
  );
};
