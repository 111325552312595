import styled from 'styled-components';

export const DashStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 40px;

  .greeting {
    display: flex;
    height: fit-content;
    align-items: baseline;
    color: ${props => props.theme.colors.aellablacktext};
  }

  .filter_notification {
    display: flex;
    align-items: center;
    position: relative;
  }

  .notification_wrapper {
    position: relative;
  }

  .alert {
    position: absolute;
    right: 0;
  }

  .back_btn_wrapper {
    display: flex;
    align-items: center;
  }

  .back_btn {
    width: 40px;
    height: 40px;
    background: ${props => props.theme.colors.aellawhite};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border-radius: 6px;
  }

  .modal_btn {
    padding: 0 24px;
  }
`;
