import './styles/index.css';
import './styles/mobileindex.css';
import 'styles/style.scss';
import React, { useContext } from 'react';
import { AuthContext } from 'context/auth';
import { SelectedItemsProvider } from 'context/selected_items';
import { ModalContextsProvider } from 'context/modals';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'GlobalStyle/App.style';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Login, Main, Register } from 'pages/index';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'pages/protected.route';
import { CreateMerchant } from 'components/DashMainHeader/register-merchant-modal';
import { DashLayout } from 'layout/Dash/index';
import { Toaster } from 'react-hot-toast';
import FallBackScreen from 'components/FallBackScreen';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ForgotPassword/password-reset';

const queryClient = new QueryClient();

function App() {
  const { isAuthed } = useContext(AuthContext);
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        position='top-right'
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      />
      <Router>
        <SelectedItemsProvider>
          <React.Suspense fallback={<FallBackScreen />}>
            <ModalContextsProvider>
              <ThemeProvider theme={theme}>
                <CreateMerchant />
                <GlobalStyle />

                <Switch>
                  <Route path='/register'>
                    <Register />
                  </Route>
                  <Route exact path='/login'>
                    <Login />
                  </Route>
                  <Route exact path='/forgot-password'>
                    <ForgotPassword />
                  </Route>

                  <Route exact path='/reset-password'>
                    <ResetPassword />
                  </Route>
                  <Route path='*'>
                    {isAuthed ? (
                      <ProtectedRoute>
                        <DashLayout>
                          <Main />
                        </DashLayout>
                      </ProtectedRoute>
                    ) : (
                      <Login />
                    )}
                  </Route>
                </Switch>
              </ThemeProvider>
            </ModalContextsProvider>
          </React.Suspense>
        </SelectedItemsProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
