import { AuthLayout } from 'layout/Auth/index';
import { LoginStyle } from '../Login/style';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from 'react-query';
import { fetcher } from 'services/apiClient';
import { Input } from 'components/Input/InputFields';
import { ButtonDefault } from 'components/Input/Buttons';
import { ButtonLoader } from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

interface IFormInput {
  email_or_phone_number: string | number;
}

const ForgotPassword = () => {
  const history = useHistory();
  const { isLoading, mutateAsync } = useMutation(
    data => fetcher('user/reset-password/initiate', data),
    {
      retry: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      const res = await mutateAsync(data);
      toast.success(res.message);
      history.push('/reset-password');
    } catch (error) {}
  };
  return (
    <AuthLayout>
      <LoginStyle>
        <form onSubmit={handleSubmit(onSubmit)} className='width100'>
          {errors.email_or_phone_number && (
            <span className='reg12 form_error'>Please check your email or phone number</span>
          )}
          <Input
            label=''
            placeholder='Email address or phone number'
            {...register('email_or_phone_number', {
              required: 'Please provide a value',
            })}
            type='text'
          />
          <div className='login_btn_wrapper'>
            <ButtonDefault disabled={isLoading} type='submit'>
              {isLoading ? <ButtonLoader /> : 'Continue'}
            </ButtonDefault>
          </div>
          <br />
          <span>
            Have an account? <Link to='/login'>Login</Link>
          </span>
        </form>
      </LoginStyle>
    </AuthLayout>
  );
};

export default ForgotPassword;
