import { useContext, useRef, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ProfileStyle, DeveloperKeysStyle } from './style';
import { useMutation } from 'react-query';
import { Input, TextArea } from 'components/Input/InputFields';
import { fetcher, handleErrors } from 'services/apiClient';
import { ButtonLoader } from 'components/Loading';
import { IFormInput, profile_type } from './types';
import { SelectedItems } from 'context/selected_items';
import { addTextToClipBoard, isNotEmptyArray } from 'utils';
import { ReactComponent as SvgCopy } from 'assets/icons/main/copy-icon.svg';
import { toast } from 'react-hot-toast';

export const Profile = ({
  name,
  email,
  address,
  phone_number,
}: profile_type) => {
  return (
    <ProfileStyle>
      <div className='image_wrapper'>
        <img src='' alt='' />
      </div>
      <div>
        <span className='reg14'>Business Name</span>
        <span className='mid18'>{name || 'N/A'}</span>
        <span className='reg14'>Address</span>
        <span className='mid18'>{address || 'N/A'}</span>
        <span className='reg14'>Phone Number</span>
        <span className='mid18'>{phone_number || 'N/A'}</span>
      </div>
      <div>
        <span className='reg14'>Email</span>
        <span className='mid18'>{email || 'N/A'}</span>
      </div>
    </ProfileStyle>
  );
};

export const WebHooks = () => {
  const history = useHistory();
  const webhookReference = useRef<HTMLSpanElement>(null);

  const { merchantID, webhook } = useContext(SelectedItems);
  const latestWebHook = webhook[webhook?.length - 1 || 0];
  console.log('latestWebHook', latestWebHook);

  const { isLoading, mutateAsync } = useMutation(formData =>
    fetcher('merchant/webhook', formData)
  );

  useEffect(() => {
    console.log('webhookReference', webhookReference.current?.innerHTML);
  }, [webhookReference]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      const res = await mutateAsync({ merchant_id: `${merchantID}`, ...data });
      toast.success(res.message);
      window.location.reload();
    } catch (error) {
      handleErrors(error, history);
    }
  };

  return (
    <DeveloperKeysStyle>
      {!isNotEmptyArray(webhook) && (
        <>
          <form className='' onSubmit={handleSubmit(onSubmit)}>
            {errors.url && (
              <span className='reg12 form_error'>Please check webhook</span>
            )}
            <Input
              label=''
              {...register('url', {
                required: 'Please provide a value',
              })}
              placeholder='https://www.example.com'
              type='text'
              isModal={true}
            />

            {errors.description && (
              <span className='reg12 form_error'>Please check description</span>
            )}
            <TextArea
              {...register('description', {
                required: 'Please provide a value',
              })}
              name='description'
              placeholder='what is the purpose of this webhook'
              label=''
            />
            <button type='submit' className='modal_btn'>
              {isLoading ? <ButtonLoader /> : 'Create WebHook'}
            </button>
            <hr className='divider' />
          </form>
        </>
      )}
      {isNotEmptyArray(webhook) && (
        <>
          <div className='keys_wrapper'>
            {webhook && (
              <div className='keys_container'>
                <span className='reg14'>CallbackURL</span>

                <div className='keys_inner_wrapper'>
                  <SvgCopy
                    onClick={() => {
                      addTextToClipBoard(
                        (webhookReference as any).current.innerHTML,
                        'webhook'
                      );
                    }}
                  />
                  <span
                    ref={webhookReference}
                    onClick={(e: any) => {
                      addTextToClipBoard(e.target.innerText, 'webhook');
                    }}
                    className='mid16 pointer pointer_key'>
                    {latestWebHook?.url}
                  </span>
                </div>

                <span className='reg14'>Description</span>
                <span className='mid16'>{latestWebHook?.description}</span>
              </div>
            )}
          </div>
        </>
      )}
    </DeveloperKeysStyle>
  );
};

export const DeveloperKeys = () => {
  const { key } = useContext(SelectedItems);
  const credentialReference = useRef<HTMLSpanElement>(null);

  return (
    <>
      <DeveloperKeysStyle>
        <div className='keys_wrapper'>
          {isNotEmptyArray(key) &&
            key.map((key: any) => (
              <div className='keys_container' key={key?.id}>
                <div>
                  <span className='reg14'>
                    <span className='key_type'>{key?.type} Key</span>
                  </span>
                  <div className='keys_inner_wrapper'>
                    <SvgCopy
                      onClick={() => {
                        addTextToClipBoard(
                          (credentialReference as any).current.innerHTML,
                          `${key?.type} key`
                        );
                      }}
                    />
                    <span
                      ref={credentialReference}
                      className='pointer pointer_key mid16'
                      onClick={(e: any) => {
                        addTextToClipBoard(
                          e.target.innerText,
                          `${key?.type} key`
                        );
                      }}>
                      {key?.key}:{key?.secret}
                    </span>
                  </div>
                </div>
                <div>
                  <span className='reg14'>Description</span>
                  <span className='mid16'>{key?.description || 'N/A'}</span>
                </div>
              </div>
            ))}
        </div>
      </DeveloperKeysStyle>
    </>
  );
};
