import { CircularProgress } from '@chakra-ui/react';

export const Loading = () => {
  return <div></div>;
};

type button_loader = {
  sm?: true;
};

export const ButtonLoader = ({ sm }: button_loader) => {
  return <CircularProgress size={sm ? '16px' : '24px'} isIndeterminate />;
};
