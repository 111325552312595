import { ModalStyle } from './style';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

const ModalLayout = ({ isOpen, setIsOpen, children }: any) => {
  const { onClose } = useDisclosure();
  return (
    <ModalStyle>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className='modal_overlay' />
        <ModalContent className='modal_content'>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </ModalStyle>
  );
};

export default ModalLayout;
