import styled from 'styled-components';

export const IconInputStyle = styled.div<{
  is_modal?: boolean;
  is_search?: boolean;
}>`
  display: flex;
  width: 100%;
  height: ${props => (props.is_modal ? '' : `fit-content`)};
  flex-direction: column;
  margin-bottom: ${props => (props.is_modal ? '12px' : `16px`)};

  .input_label {
    margin-bottom: ${props => (props.is_modal ? '4px' : `8px`)};
    font-size: ${props => (props.is_modal ? '12px' : ``)};
    font-weight: ${props => (props.is_modal ? 300 : ``)};
  }

  .icon_input_container {
    display: flex;
    width: 100%;
    height: ${props => (props.is_modal ? '30px' : `56px`)};
    position: relative;
  }

  .input::placeholder {
    color: ${props => props.theme.colors.aellagrey};
    font-size: ${props => (props.is_modal ? '0.75rem' : '1.125rem')};
    font-weight: 300;
  }

  .input {
    top: 0;
    left: 0;
    height: ${props => (props.is_modal ? '30px' : `inherit`)};
    width: inherit;
    position: absolute;
    padding: 0 0 0 16px;
    font-size: ${props => (props.is_modal ? '0.75rem' : '1.125rem')};
    border: ${props =>
      props.is_modal ? 'none' : ` 1px solid rgba(185, 185, 185, 0.7)`};
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    font-weight: 300;
    background-color: ${props =>
      props.is_modal ? '#edf2ff' : `${props.theme.colors.aellawhite}`};
  }

  .input:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.aellablue};
    box-shadow: 0 0 0 2pt rgba(32, 84, 210, 0.2);
  }
`;

export const SelectFileStyle = styled(IconInputStyle)``;

export const SearchInputStyle = styled(IconInputStyle)`
  margin-bottom: 0;
  .icon_input_container {
    height: 40px;
    width: 300px;
  }

  .input::placeholder {
    color: ${props => props.theme.colors.aellagrey};
    font-size: 0.875rem;
    font-weight: 300;
  }

  input {
    font-size: 0.875rem;
  }
`;

export const TextAreaStyle = styled.div<{ is_modal?: boolean }>`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    color: #232323;
  }

  textarea {
    padding: ${props => (props.is_modal ? '10px 0 0 16px' : '5px')};
    resize: none;
    outline: none;
    width: 100%;
    background: ${props => (props.is_modal ? '#fff' : '#edf2ff')};
    border: ${props =>
      props.is_modal ? '1px solid rgba(185, 185, 185, 0.7)' : 'none'};
    border-radius: 4px;
    height: ${props => (props.is_modal ? '120px' : '72px')} ;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: ${props => (props.is_modal ? '1.125rem' : '0.75rem')} ;
  }
`;

export const SelectStyle = styled(TextAreaStyle)`
  select {
    width: 100%;
    height: 30px;
    background: #edf2ff;
    border: none;
    margin-bottom: 20px;
    font-weight: 300;
    outline: none;
  }
`;
