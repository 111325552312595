import styled from 'styled-components';

export const DashBoardStyle = styled.div`
  .main_dash_container {
    margin: 100px 24px 24px 280px;
    background: ${props => props.theme.colors.aellabgblue};
    border-radius: 8px;
    padding-bottom: 40px;
  }

  .sidebar_wrapper {
    transition: all 1s;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
  }

  .main_dash_container {
    @media only screen and (max-width: 600px) {
      margin: 100px 24px 24px 24px;
    }
  }
`;
