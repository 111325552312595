import { toast } from 'react-hot-toast';

export const getStatus = (status: string) => {
  if (status === undefined || status === null) {
    return '';
  }

  status = status.toLowerCase();
  if (
    status === 'dr' ||
    status === 'failed' ||
    status === 'error' ||
    status === 'pending-renewal'
  ) {
    return 'failed';
  }

  if (
    status === 'paid' ||
    status === 'approved' ||
    status === 'cr' ||
    status === 'completed' ||
    status === 'success'
  ) {
    return 'success';
  }

  if (
    status === 'running' ||
    status === 'partially paid' ||
    status === 'pending' ||
    status === 'partial'
  ) {
    return 'pending';
  }
};

export const handleData = (data: string) => {
  if (data === null || data === undefined) {
    return 'N/A';
  } else {
    return data;
  }
};

export const fromatPayDay = (payday: number) => {
  if (payday === null || payday === undefined) {
    return 'N/A';
  }
  const str_payday = payday.toString();
  if (str_payday.endsWith('3')) {
    return `${str_payday}rd of Every Month`;
  } else {
    return `${str_payday}th of Every Month`;
  }
};

export const currencyFormat = (amount: any) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  if (amount === null || amount === undefined) {
    return 'N/A';
  }
  if (amount % 1 === 0) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  return amount.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const truncateString = (textString: string, num: number) => {
  if (textString.length <= num) {
    return textString;
  }
  return textString.slice(0, num) + '...';
};

export const isDefined = (value: any) => typeof value !== 'undefined';

//Date Filtering Functions
let now = new Date();
const now_formatted = now.toISOString().split('T')[0];
const today = now.getDate();
const this_month = now.getMonth() + 1;
const this_year = now.getFullYear();

export const getToday = () => {
  let next_day = now;
  next_day.setDate(now.getDate() + 1);
  return [now_formatted, next_day.toISOString().split('T')[0]];
};

export const getThisWeek = () => {
  const current_day = new Date(this_year, this_month, today);
  const start_of_week = new Date(
    current_day.setDate(current_day.getDate() - current_day.getDay())
  );
  const formatted_start_of_week = start_of_week.toISOString().split('T')[0];
  return [formatted_start_of_week, now_formatted];
};

export const getLastThirtyDays = () => {
  let last_thirty_days = now;
  last_thirty_days.setDate(now.getDate() - 30);
  return [now_formatted, last_thirty_days.toISOString().split('T')[0]];
};

export const getThisYear = () => {
  let start_of_year = new Date(new Date().getFullYear(), 0, 1);
  const formatted_start_of_year = start_of_year.toISOString().split('T')[0];
  if (formatted_start_of_year === now_formatted) {
    return getToday();
  }
  return [now_formatted, formatted_start_of_year];
};

export const isEmptyObject = (obj: {}) => Object.keys(obj).length === 0;

export const isNotEmptyArray = (arr: any) =>
  Array.isArray(arr) && arr.length > 0;

export const addTextToClipBoard = (innerText: string, item: string) => {
  navigator.clipboard.writeText(innerText).then(
    function () {
      toast.success(`${item} copied to clipboard`);
    },
    function () {
      toast.error(`Failed to copy ${item} to clipboard`);
    }
  );
};
